// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap";
import { TweenMax, TweenLite, TimelineMax, CSSPlugin, ScrollToPlugin, Draggable, Power0, Power1, Power2, Power3, Power4, Linear, Elastic } from "gsap/all";
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

// Audio player and wave visualizer
import Plyr from 'plyr';
import WaveSurfer from "./wavesurfer";


ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

// Loading bootstrap with optional features
initBootstrap({
	tooltip: true,
	popover: true,
	toasts: true,
});

// Remove this demo code, that's only here to show how the .env file works!
// if (process.env["HELLO"]) {
//   console.log(`Hello ${process.env.HELLO}!`);
// }

// $(document).ready(function(){
//     //window.jQuery = $;
//     //window.$ = $;
//
//     //Try to play the video if the window resizes and exposes it
//     if ($('video').length > 0){
//         $( window ).resize(function() {
//             if ($('video:visible')[0]) {
//                 $('video:visible')[0].play();
//             }
//         });
//     }
//
// });

function copyText(selectorId) {
	/* Get the text field */
	var copyText = document.getElementById(selectorId);

	/* Select the text field */
	copyText.select();
	copyText.setSelectionRange(0, 99999); /*For mobile devices*/

	/* Copy the text inside the text field */
	document.execCommand("copy");

	//console.log("Copied the text: " + copyText.value);
}


// -- HoverIntent -- //

// load namespace
let SV = window.SV || {};

SV.HoverIntent = (function() {

	// constructor
	return function(elements, userConfig) {

		// private members

		const defaultOptions = {
			exitDelay: 400,
			interval: 100,
			sensitivity: 7,
		};
		let config = {};

		let currX, currY, prevX, prevY;
		let allElems, pollTimer, exitTimer;

		// private methods

		// override default options with user config
		const extend = function(defaults, userArgs) {
			for (let i in userArgs) {
				defaults[i] = userArgs[i];
			}

			return defaults;
		};

		// update mouse position
		const mouseTrack = function(ev) {
			currX = ev.pageX;
			currY = ev.pageY;
		};

		// check if mouse movement has slowed enough to trigger active state
		const mouseCompare = function(targetElem) {
			const distX = prevX - currX, distY = prevY - currY;
			const distance = Math.sqrt(distX*distX + distY*distY);

			if (distance < config.sensitivity) {
				// if we re-entered an element, cancel delayed exit and clear any active elements immediately
				clearTimeout(exitTimer);
				for (let elem of allElems) {
					if (elem.isActive) {
						config.onExit(elem);
						elem.isActive = false;
					}
				}

				// trigger hover
				config.onEnter(targetElem);
				targetElem.isActive = true;
			} else {
				// update previous coordinates and try again later
				prevX = currX;
				prevY = currY;
				pollTimer = setTimeout(function() {
					mouseCompare(targetElem);
				}, config.interval);
			}
		};

		const init = function(elements, userConfig) {
			if (!userConfig || !userConfig.onEnter || !userConfig.onExit) {
				throw 'onEnter and onExit callbacks must be provided';
			}
			config = extend(defaultOptions, userConfig);
			allElems = elements;

			for (let elem of allElems) {
				// holds current element state
				elem.isActive = false;
				// keeps track of mouse position
				elem.addEventListener('mousemove', mouseTrack);

				elem.addEventListener('mouseenter', function(ev) {
					// set initial entry position
					prevX = ev.pageX;
					prevY = ev.pageY;
					// if this element is already active, cancel exit
					if (elem.isActive) {
						clearTimeout(exitTimer);
						return;
					}

					// while mouse is over this element, check distance every 100ms
					pollTimer = setTimeout(function() {
						mouseCompare(elem);
					}, config.interval);
				});
				elem.addEventListener('mouseleave', function(ev) {
					clearTimeout(pollTimer);
					if (!elem.isActive)
						return;

					exitTimer = setTimeout(function() {
						config.onExit(elem);
						elem.isActive = false;
					}, config.exitDelay);
				});
			}
		};

		init(elements, userConfig);
	};

})();


var app = {
	navBackground: false,
	init: function() {
		window.addEventListener('scroll', app.scroll, false);
		fixedHiddenNav();
		scrollAnimation();
		setSelectStyled();
		audioPlayer();
		videoPlayer();
		socialShareFollow();
		addressBarHeight();
		alertBar();
	},
	scroll: function() {
		var scrollPos = document.documentElement.scrollTop;
		var windowHeight = window.innerHeight;
		var headerHeight = document.querySelector('header').offsetHeight;
		var firstSectionHeight = document.querySelector('main section:first-child').offsetHeight;

		if(scrollPos > (firstSectionHeight)) {
			if(!app.navBackground) {
				document.querySelector('body').classList.add("nav_background");
				app.navBackground = true;
			}
		} else {
			if(app.navBackground) {
				document.querySelector('body').classList.remove("nav_background");
				app.navBackground = false;
			}
		}
	}
};

function addressBarHeight() {
	const actualHeight = window.innerHeight;
	const elementHeight = document.querySelector('#control-height').clientHeight;

	const barHeight = elementHeight - actualHeight;

	document.documentElement.style.setProperty('--addressbar-height', (barHeight + "px"));

	function documentHeight() {
		document.documentElement.style.setProperty('--doc-height', (actualHeight + "px"));
	}
	window.addEventListener('resize', documentHeight);
	documentHeight();
}


// When the menu reaches the top of the screen switch to position fixed. If mobile then also hide the menu until the user scrolls up.
function fixedHiddenNav() {
	var lastScrollTop = 0;
	var startedGoingUp = 0;
	var startedGoingDown = 0;
	var minScrollUp = 100;
	var minScrollDown = 250;
	var closeToBottom = 200;
	var pageHeight = 0;
	var windowHeight = 0;

	function setHeights() {
		pageHeight = document.querySelector('.wrapper').offsetHeight;
		windowHeight = window.innerHeight;
	}

	window.addEventListener('resize', setHeights);
	setHeights();

	window.addEventListener("scroll", function(){
		var currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

		// Open the condensed nav if nearing the bottom of the page
		if ((currentScrollTop + closeToBottom) > (pageHeight - windowHeight)){
			document.querySelector('body').classList.remove("hide_nav");
		// If the user has scrolled down at least the minScrollDown value then condense the nav
		} else if (currentScrollTop > (lastScrollTop)){
			startedGoingUp = 0;

			if(startedGoingDown == 0) {
				startedGoingDown = currentScrollTop;
			}

			var difference = currentScrollTop - startedGoingDown;

			if(difference > minScrollDown) {
				document.querySelector('body').classList.add("hide_nav");
				document.querySelector('body').classList.add("hide_wordmark");
			}
		// If the user has scrolled up at least the minScrollUp value then condense the nav
		} else {
			startedGoingDown = 0;

			if(startedGoingUp == 0) {
				startedGoingUp = currentScrollTop;
			}

			var difference = startedGoingUp - currentScrollTop;

			if(difference > minScrollUp || currentScrollTop == 0) {
				document.querySelector('body').classList.remove("hide_nav");

				if(currentScrollTop < minScrollDown) {
					document.querySelector('body').classList.remove("hide_wordmark");
				}
			}
		}
		lastScrollTop = currentScrollTop;
	}, false);
}

function debounce(func){
	var timer;
	return function(event){
		if(timer) clearTimeout(timer);
		timer = setTimeout(func,100,event);
	};
}

var carousel = document.querySelectorAll('.carousel');

carousel.forEach(function(carousel) {

	if(carousel.classList.contains("carousel_overflow")) {
		var carouselSlides = carousel.querySelectorAll('.carousel-item');

		var i = 0;
		carouselSlides.forEach(function(carouselSlide) {
			if(carouselSlide.nextElementSibling) {
				var cloneNext = carouselSlide.nextElementSibling.querySelectorAll(".carousel_content")[0].cloneNode(true);

				if(typeof cloneNext !== 'undefined') {
					cloneNext.classList.add('next_slide');
					carouselSlide.querySelectorAll(".carousel_content")[0].after(cloneNext);
				}
			} else {
				var clonePrevious = carouselSlides[0].querySelectorAll(".carousel_content")[0].cloneNode(true);

				if(typeof clonePrevious !== 'undefined') {
					clonePrevious.classList.add('next_slide');
					carouselSlide.querySelectorAll(".carousel_content")[0].after(clonePrevious);
				}
			}

			if(carouselSlide.previousElementSibling) {
				var clonePrev = carouselSlide.previousElementSibling.querySelectorAll(".carousel_content")[0].cloneNode(true);

				if(typeof clonePrev !== 'undefined') {
					clonePrev.classList.add('prev_slide');
					carouselSlide.querySelectorAll(".carousel_content")[0].after(clonePrev);
				}
			} else {
				var cloneLast = carouselSlides[carouselSlides.length - 1].querySelectorAll(".carousel_content")[0].cloneNode(true);

				if(typeof cloneLast !== 'undefined') {
					cloneLast.classList.add('prev_slide');
					carouselSlide.querySelectorAll(".carousel_content")[0].after(cloneLast);
				}
			}

			//carouselSlide.querySelectorAll(".carousel_content")[0].classList.add('current_slide');

			i++;
		});
	}

	var height = carousel.offsetHeight;

	carousel.querySelectorAll(".carousel-item").forEach(function(carouselItem) {
		if (carouselItem.offsetHeight > height) {
			height = carouselItem.offsetHeight;
		}
	});
	
	if(height > 0) {
		var carouselInner = carousel.querySelectorAll(".carousel-inner")[0];
		carouselInner.style.height = height +"px";

		carousel.addEventListener('slide.bs.carousel', function (event) {
			setTimeout(function() {
				var newHeight = event.relatedTarget.offsetHeight;

				var carouselInner = carousel.querySelectorAll(".carousel-inner")[0];
				carouselInner.style.height = newHeight +"px";
			}, 5);
		})

		window.addEventListener("resize",debounce(function(event){
			carouselInner.style = "";

			setTimeout(function() {
				var newHeight = carousel.querySelector(".carousel-item.active").offsetHeight;

				var carouselInner = carousel.querySelectorAll(".carousel-inner")[0];
				carouselInner.style.height = newHeight +"px";
			}, 5);
		}));
	}
});


var tabs = document.querySelectorAll('.tabs');

tabs.forEach(function(tabs) {
	// var tabPane = document.querySelector(event.target.dataset.bsTarget);
	// var newHeight = tabPane.offsetHeight;
	// var tabsContent = tabs.querySelectorAll(".tab-content")[0];
	// tabsContent.style.height = newHeight +"px";

    tabs.addEventListener('show.bs.tab', function (event) {
    	setTimeout(function() {
    		var tabPane = document.querySelector(event.target.dataset.bsTarget);

    		var newHeight = tabPane.offsetHeight;

    		var tabsContent = tabs.querySelectorAll(".tab-content")[0];
    		//tabsContent.style.height = newHeight +"px";

    	}, 500);
    })
});

window.addEventListener("resize",debounce(function(event){
	tabs.forEach(function(tabs) {
		var tabPane = document.querySelector(".tab-pane.active");
		var newHeight = tabPane.offsetHeight;

		//console.log("newHeight: " + newHeight);

		var tabsContent = tabs.querySelectorAll(".tab-content")[0];
		//tabsContent.style.height = newHeight +"px";
	})
}));


// https://www.cssscript.com/sv-hover-intent/
var navPanel = document.querySelectorAll('.has_nav_panel');
var instance = new SV.HoverIntent(navPanel, {
	onEnter: function(targetItem) {
		if(!isTouchScreen() && !mobileMenuVisible()) {
			document.querySelector('body').classList.add("nav_panel_open");
			targetItem.classList.add("active");
		}
	},
	onExit: function(targetItem) {
		if(!isTouchScreen() && !mobileMenuVisible()) {
			document.querySelector('body').classList.remove("nav_panel_open");
			targetItem.classList.remove("active");
		}
	},
	exitDelay: 500,
	interval: 100
});

var secondaryNavPanel = document.querySelectorAll('.has_secondary_nav_panel');
var instance = new SV.HoverIntent(secondaryNavPanel, {
	onEnter: function(targetItem) {
		if(!isTouchScreen() && !mobileMenuVisible()) {
			document.querySelector('body').classList.add("secondary_nav_panel_open");
			targetItem.classList.add("active");
		}
	},
	onExit: function(targetItem) {
		if(!isTouchScreen() && !mobileMenuVisible()) {
			document.querySelector('body').classList.remove("secondary_nav_panel_open");
			targetItem.classList.remove("active");
		}
	},
	exitDelay: 500,
	interval: 100
});


var mainPanelLinks = document.querySelectorAll('.main_nav .nav-item.has_nav_panel .nav-link');

mainPanelLinks.forEach((link) => {
	link.onclick = (event) => {
		if(isTouchScreen() && !event.target.closest('.has_nav_panel').classList.contains("active")) {
			event.preventDefault();
		}

		document.querySelector('body').classList.remove("secondary_nav_panel_open");

		document.querySelectorAll('.main_nav .has_secondary_nav_panel').forEach(link => {
			link.classList.remove('active');
		});

		if(event.target.closest('.has_nav_panel').classList.contains("active")) {
			mainPanelLinks.forEach(link => {
				link.closest('.has_nav_panel').classList.remove('active');
			});

			document.querySelector('body').classList.remove("nav_panel_open");
		} else {
			event.preventDefault();

			mainPanelLinks.forEach(link => {
				link.closest('.has_nav_panel').classList.remove('active');
			});

			event.target.closest('.has_nav_panel').classList.add("active");
			document.querySelector('body').classList.add("nav_panel_open");
			link.closest('.navbar-nav').classList.add('nav_panel_active');
		}

	};
});

var secondaryPanelLinks = document.querySelectorAll('.main_nav .has_secondary_nav_panel > a');

secondaryPanelLinks.forEach((link) => {
	link.onclick = (event) => {
		if(isTouchScreen() && !event.target.closest('.has_secondary_nav_panel').classList.contains("active")) {
			event.preventDefault();
		}

		if(event.target.closest('.has_secondary_nav_panel').classList.contains("active")) {
			secondaryPanelLinks.forEach(link => {
				link.closest('.has_secondary_nav_panel').classList.remove('active');
			});

			document.querySelector('body').classList.remove("secondary_nav_panel_open");
		} else {
			event.preventDefault();

			secondaryPanelLinks.forEach(link => {
				link.closest('.has_secondary_nav_panel').classList.remove('active');
			});

			event.target.closest('.has_secondary_nav_panel').classList.add("active");
			document.querySelector('body').classList.add("secondary_nav_panel_open");
			link.closest('.navbar-nav').classList.add('secondary_nav_panel_active');
		}
	};
});

function isTouchScreen() {
    return window.matchMedia('(hover: none)').matches;
}

function mobileMenuVisible() {
	if(window.innerWidth < 992) {
		return true;
	} else {
		return false;
	}
}

var navPanelBack = document.querySelectorAll('.main_nav .nav_panel_back .link_back');

navPanelBack.forEach((link) => {
	link.onclick = (event) => {
		if(window.innerWidth < 992) {
			event.preventDefault();

			document.querySelector('body').classList.remove("nav_panel_open");
			event.target.closest('.nav_panel_active').classList.remove("nav_panel_active");
			event.target.closest('.has_nav_panel').classList.remove("active");
		}
	};
});

var secondaryPanelBack = document.querySelectorAll('.main_nav .secondary_nav_panel_back .link_back');

secondaryPanelBack.forEach((link) => {
	link.onclick = (event) => {
		if(window.innerWidth < 992) {
			event.preventDefault();

			document.querySelector('body').classList.remove("secondary_nav_panel_open");
			event.target.closest('.secondary_nav_panel_active').classList.remove("secondary_nav_panel_active");
			event.target.closest('.has_secondary_nav_panel').classList.remove("active");
		}
	};
});

function resetMenu() {
	// console.log("RSET");

	var links = document.querySelectorAll('.nav_panel_active');

	links.forEach((link) => {
		link.classList.remove('nav_panel_active', 'secondary_nav_panel_active');
	});

	var links2 = document.querySelectorAll('.has_nav_panel');

	links2.forEach((link) => {
		link.classList.remove('active');
	});

	var links3 = document.querySelectorAll('.has_secondary_nav_panel');

	links3.forEach((link) => {
		link.classList.remove('active');
	});

	document.querySelector('body').classList.remove("nav_panel_open", "menu_open", "secondary_nav_panel_open");

	document.querySelector('.navbar-toggler').classList.remove("active");
}

document.querySelector('.navbar-toggler').onclick = (event) => {
	if(window.innerWidth < 992) {
		console.log(event.target.classList);

		if(event.target.closest('.navbar-toggler').classList.contains('active')) {
			resetMenu();
		} else {
			//console.log("ELSE");
			document.querySelector('body').classList.add("menu_open");
			document.querySelector('.navbar-toggler').classList.add("active");
		}
	}
}

document.querySelector('.page_cover').onclick = (event) => {
	if(window.innerWidth < 992) {
		document.querySelector('.navbar-toggler').click();
	} else if(isTouchScreen()) {
		resetMenu();
	}
}

if (!window.matchMedia("(any-hover: none)").matches) {
	var cardHover = document.querySelectorAll('.card_container');
	var instance = new SV.HoverIntent(cardHover, {
		onEnter: function(targetItem) {
			if(targetItem.closest(".carousel")) {
				targetItem.closest(".carousel").classList.add("card_hover_active");
			}
			targetItem.classList.add("active");
		},
		onExit: function(targetItem) {
			targetItem.classList.remove("active");
		},
		exitDelay: 50,
		interval: 100
	});

	var cardGrowHover = document.querySelectorAll('.card_grow_container');
	var instance = new SV.HoverIntent(cardGrowHover, {
		onEnter: function(targetItem) {
			if(targetItem.closest(".cards_grow_width")) {
				targetItem.closest(".cards_grow_width").classList.add("card_hover_active");
			}
			targetItem.parentElement.querySelectorAll( ".active" ).forEach( e => e.classList.remove( "active" ) );
			targetItem.classList.add("active");
		},
		onExit: function(targetItem) {
			//targetItem.classList.remove("active");
		},
		exitDelay: 50,
		interval: 50
	});
}

var cardGrowClicks = document.querySelectorAll('.card_grow_container');
cardGrowClicks.forEach(function(card) {
	card.onclick = (event) => {
		if(window.innerWidth < 992) {
			if(!card.classList.contains("active")) {
				event.preventDefault();

				if(card.closest(".cards_grow_width")) {
					card.closest(".cards_grow_width").classList.add("card_hover_active");
				}
				card.parentElement.querySelectorAll( ".active" ).forEach( e => e.classList.remove( "active" ) );
				card.classList.add("active");
			}
		}
	}
});

var carouselExit = document.querySelectorAll('.carousel');
var instance = new SV.HoverIntent(carouselExit, {
	onEnter: function(targetItem) {},
	onExit: function(targetItem) {
		targetItem.classList.remove("card_hover_active");
	},
	exitDelay: 50
});

var carouselNextPrev = document.querySelectorAll('.carousel-control-next, .carousel-control-prev');
var instance = new SV.HoverIntent(carouselNextPrev, {
	onEnter: function(targetItem) {
		targetItem.closest(".carousel").classList.remove("card_hover_active");
	},
	onExit: function(targetItem) {},
	interval: 50
});


var bookmarkButtons = document.querySelectorAll('.btn_bookmark');
bookmarkButtons.forEach(function(button) {
	button.onclick = (event) => {
		if(button.classList.contains("active")) {
			button.classList.remove("active");
		} else {
			button.classList.add("active");
		}
	}
});

var filterPanels = document.querySelectorAll('.has_filter_panel > a, .has_filter_panel > .filter_panel_params_active');
filterPanels.forEach(function(button) {
	button.onclick = (event) => {
		event.preventDefault();

		var filterSidebar = document.querySelector( "aside.filter" );

		if(button.closest(".has_filter_panel").classList.contains("active")) {
			//button.classList.remove("active");
		} else {
			button.closest(".has_filter_panel").classList.add("active");
			button.closest("aside").classList.add("filter_panel_active");

			var panelHeight = button.nextElementSibling.querySelector( ".content" ).offsetHeight;
			filterSidebar.setAttribute("style","height:" + panelHeight + "px");

			window.addEventListener("resize",debounce(function(event){
				filterSidebar.setAttribute("style","");
			}));
		}
	}
});

var filterPanelsRemoveParamButtons = document.querySelectorAll('.has_filter_panel > .filter_panel_params_active .btn-close');
filterPanelsRemoveParamButtons.forEach(function(button) {
	button.onclick = (event) => {
		event.stopPropagation()
	}
});

if(document.querySelector('aside.filter')) {
	if(window.innerWidth > 992) {
		setTimeout(function() {
			var panelHeight = document.querySelector( "aside.filter" ).offsetHeight;
			var filterSidebar = document.querySelector( "aside.filter" );
			filterSidebar.setAttribute("style","height:" + panelHeight + "px");
		}, 50);
	}
}

var filterCheckboxs = document.querySelectorAll('aside.filter input[type="checkbox"]');
filterCheckboxs.forEach(function(checkbox) {
	checkbox.addEventListener('change', (event) => {
		event.preventDefault();
		window.location.href = event.currentTarget.value;
	})
});

function dateInputCorrect(value) {
	if(value) {
		var date = new Date( value );
		var year = date.getFullYear();
		
		if(year >= 2020) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}

var firstDateInputFocusChange = true;
var filterDates = document.querySelectorAll('aside.filter input[type="date"]');
filterDates.forEach(function(datepicker) {
	datepicker.addEventListener('change', (event) => {
		event.preventDefault();

		var url = new URL(window.location.href);

		if (event.currentTarget.id == "startDate" || event.currentTarget.id == "endDate") {
			if (event.currentTarget.id == "startDate") {
				if(dateInputCorrect(event.currentTarget.value)) {
					var endDateInput = document.querySelector('aside.filter #endDate');
					
					if(dateInputCorrect(endDateInput.value)) {
						url.searchParams.set('startDate', event.currentTarget.value);
						url.searchParams.set('endDate', endDateInput.value);
						window.location.href = url.href;
					} else {
						if(firstDateInputFocusChange) {
							endDateInput.focus();
							firstDateInputFocusChange = false;
						}
					}
				}
			} else if (event.currentTarget.id == "endDate") {
				if(dateInputCorrect(event.currentTarget.value)) {
					var startDateInput = document.querySelector('aside.filter #startDate');

					if(dateInputCorrect(startDateInput.value)) {
						url.searchParams.set('endDate', event.currentTarget.value);
						url.searchParams.set('startDate', startDateInput.value);
						window.location.href = url.href;
					} else {
						if(firstDateInputFocusChange) {
							startDateInput.focus();
							firstDateInputFocusChange = false;
						}
					}
				}
			}
		} else {
			url.searchParams.set(event.currentTarget.name, event.currentTarget.value);
			window.location.href = url.href;
		}
	})
});

var filterPanelsBack = document.querySelectorAll('.has_filter_panel .back');
filterPanelsBack.forEach(function(button) {
	button.onclick = (event) => {
		//console.log("CLICK");
		//if(button.classList.contains("active")) {
			button.closest(".has_filter_panel").classList.remove("active");
			button.closest(".has_filter_panel").classList.add("here");
			button.closest("aside").classList.remove("filter_panel_active");

			var panelHeight = document.querySelector( "aside.filter > .content" ).offsetHeight;
			var filterSidebar = document.querySelector( "aside.filter" );
			filterSidebar.setAttribute("style","height:" + panelHeight + "px");
		//}
	}
});

var filterAccordionButton = document.querySelectorAll('.accordion-filter-button');
filterAccordionButton.forEach(function(button) {
	button.onclick = (event) => {
		var filterSidebar = document.querySelector( "aside.filter" );
		filterSidebar.setAttribute("style","");
	}
});


// // Smooth transition between elements of different heights in Bootstrap carousel
// // https://stackoverflow.com/questions/41626960/how-to-smooth-transition-between-elements-of-different-heights-in-bootstrap-caro
// carousel.addEventListener('slide.bs.carousel', function (event) {
// 	var height = $(event.relatedTarget).height();
// 	var $innerCarousel = $(event.target).find('.carousel-inner');

// 	event.relatedTarge.setAttribute("style","width:500px");
// });


function scrollAnimation() {
	if ( document.querySelector( ".locations_scroll" ) ) {
		var controller = new ScrollMagic.Controller();

		var locationIndex = 0;
		var locations = document.querySelectorAll('.locations_scroll .locations_list li .location');

		var locationsDuration = "100%";

		if(window.innerWidth < 576) {
			locationsDuration = "85%";
		}

		locations.forEach(function(location) {
			//var tween = TweenMax.to(location, {autoAlpha: 1, color: "#D3B975" });

			var tween = new TimelineMax()
				.to(location, 1,{
						opacity: .05,
						color: "#999999"
					}
				)
				.to(location, 1,{
						opacity: 1,
						color: "#FFFFFF"
					}
				)
				.to(location, 1,{
						opacity: .05,
						color: "#999999"
					}
				);

			var scene = new ScrollMagic.Scene({triggerElement: location, duration: locationsDuration, triggerHook: 1})
				.setTween(tween)
				.addTo(controller);
			locationIndex++;
		});

		var locations = document.querySelector('.locations_list');
		var sticky = document.querySelector('.sticky');

		var pinDuration = locations.offsetHeight - sticky.offsetHeight + 30;

		var headlineTriggerHook = .4;

		if(window.innerWidth < 576) {
			headlineTriggerHook = .1;
		}

		var pinLocationsHeadline = new ScrollMagic.Scene({
			triggerElement: '.sticky',
			triggerHook: headlineTriggerHook,
			duration: pinDuration
		})
		.setPin('.sticky', {
			pushFollowers: false
		})
		.addTo(controller);
	}
}


function audioPlayer() {
	var audioElements = document.querySelectorAll('.audio_element');
	var audioPlayers = [];

	audioElements.forEach(function(audioElement) {
		var audioPlayer = audioElement.querySelector('.audio_player');
		var audioTitle = audioPlayer.dataset.title;

		const player = new Plyr(audioPlayer, {
			title: audioTitle,
			controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume']
		});

		audioPlayers.push(player);

		player.on('play', function() {
			audioPlayers.forEach(otherPlayer => {
				if (otherPlayer !== player && otherPlayer.playing) {
					otherPlayer.pause();
				}
			});
		});

		var waveform = audioElement.querySelector('.waveform');

		if(waveform) {
			var wavesurferContainer = audioElement.querySelector('.waveform_container');
			var waveHeight = wavesurferContainer.offsetHeight * 2;

			var wavesurfer = WaveSurfer.create({
				container: waveform,
				waveColor: '#ffffff',
				progressColor: '#D3B975',
				cursorColor: '#D3B975',
				mediaControls: true,
				partialRender: true,
				barWidth: 8,
				barRadius: 0,
				cursorWidth: 2,
				height: waveHeight,
				barGap: 3,
				responsive: true,
			});

			var audioFile = waveform.dataset.audio;

			wavesurfer.load(audioFile);
		}
	});
}

function videoPlayer() {
	var video = document.querySelector( ".hopestream_ajax" );

	if ( video ) {
		var hopestreamId = video.dataset.hopestream;

		fetch("/wp-admin/admin-ajax.php?action=rv_hopestream_video_data&hopestream_id=" + hopestreamId)
			.then(response => response.json())
			.then(urls => {
				if(urls.videoUrl) {
					video.src = urls.videoUrl;
				}
			})
		.catch(err => console.log(err))
	}
}

function showBannerOnStaging() {
    if (window.location.host === 'rv.aplaceformy.site') {
        document.getElementById('corner-triangle').setAttribute('style', 'display: block');
    }
}

// $('.select_heading select').on('change', function() {
// 	var value = $(this).find("option:selected").text();
// 	$(this).siblings('.select_heading_value').text(value);
// });

document.querySelector('.select_styled select').onchange = function(event){
	event.target.nextElementSibling.innerHTML = event.target.options[event.target.selectedIndex].text;
};

function setSelectStyled() {
	var selects = document.querySelectorAll('.select_styled select');
	selects.forEach(function(select) {
		select.nextElementSibling.innerHTML = select.options[select.selectedIndex].text;
	});
}

document.querySelector('.location_selector .select_styled select').onchange = function(event){
	window.location.href = window.location.hostname + "/campus/" + event.target.options[event.target.selectedIndex].value + "/";
};


// If the page has social sharing links they will change to position fixed when they reach the top of the page and remain fixed for the duration of the main content
function socialShareFollow() {
	let minimumScreenWidth = 1240;

	if(window.innerWidth > minimumScreenWidth) {
		let shareElement = document.querySelector(".share");

		if(shareElement) {
			let width = shareElement.offsetWidth;
			shareElement.style.width = width;

			let sidebarHeight = shareElement.offsetHeight;
			let contentHeight = document.querySelector("article").offsetHeight;

			if(sidebarHeight < contentHeight) {
				let elementPosition;
				let elementHeight;
				let elementWidth;

				let containerPosition;
				let containerPositionTop;
				let containerHeight;

				let positionToCenter;
				let distanceNeedToCenter;

				function sizes() {
					if(window.innerWidth > minimumScreenWidth) {
						elementPosition = shareElement.getBoundingClientRect();
						elementHeight = shareElement.offsetHeight;
						elementWidth = shareElement.offsetWidth;

						containerPosition = document.querySelector("article").getBoundingClientRect();
						containerHeight = document.querySelector("article").offsetHeight;

						positionToCenter = ((window.innerHeight/2) - (elementHeight/2));
						containerPositionTop = containerPosition.top + document.documentElement.scrollTop
						distanceNeedToCenter = containerPositionTop - positionToCenter;
					}
				}

				function assignPosition() {
					if(window.innerWidth > minimumScreenWidth) {

						if(document.documentElement.scrollTop > distanceNeedToCenter) {

							if(document.documentElement.scrollTop > (containerPositionTop + containerHeight - positionToCenter - elementHeight)) {
								const styles = {
									position: 'absolute',
									top: 'auto',
									bottom: '0',
									left: 'auto',
									width: elementWidth + 'px'
								};

								Object.assign(shareElement.style, styles);
							} else {
								const styles = {
									position: 'fixed',
									top: positionToCenter + 'px',
									bottom: 'auto',
									left: elementPosition.left + 'px',
									width: elementWidth + 'px'
								};

								Object.assign(shareElement.style, styles);
							}
						} else {
							const styles = {
								position: 'absolute',
								top: '0',
								bottom: 'auto',
								left: 'auto',
								width: elementWidth + 'px'
							};

							Object.assign(shareElement.style, styles);
						}
					} else {
						shareElement.style.cssText = "";
					}
				}

				sizes();

				window.addEventListener("resize",debounce(function(event){
					shareElement.style.cssText = "";
					sizes();
				}));

				document.addEventListener('scroll', function(e) {
					assignPosition();
				});
			}
		}
	}
}


var filtering = document.getElementById('filtering');

if(filtering) {
	filtering.addEventListener('submit', function () {
	    var allInputs = filtering.getElementsByTagName('input');

	    for (var i = 0; i < allInputs.length; i++) {
	        var input = allInputs[i];

	        if (input.name && !input.value) {
	            input.name = '';
	        }
	    }
	});
}

var tableRowLinks = document.querySelectorAll('.table tr[data-href]');

tableRowLinks.forEach((row) => {
	row.onclick = (event) => {
		window.location = event.target.closest('tr[data-href]').dataset.href;
	};
});

function alertBar() {
	if(document.querySelector('.alert_bar')) {

		function setAlertBarHeightVar() {
			document.documentElement.style.setProperty('--alert-bar-height', document.querySelector('.alert_bar').offsetHeight + "px");
		}
		window.addEventListener('resize', setAlertBarHeightVar);

		setAlertBarHeightVar();
	}
}

document.addEventListener('DOMContentLoaded', function() {
	app.init();
	document.querySelector('body').classList.add("loaded");

	//https://stackoverflow.com/questions/33606565/is-it-possible-to-calculate-the-viewport-width-vw-without-scrollbar/34884924
	document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");

	if(document.querySelector('.cards_grow_width')) {
		document.documentElement.style.setProperty('--cards-grow-width', document.querySelector('.cards_grow_width').offsetWidth + "px");
	}

    showBannerOnStaging();
});
